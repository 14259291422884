import request from "@/libs/request";
/**
 * 发送验证码（注册）
 * @param {*} data 
 * @returns 
 */
export function registerVerify(data){
    return request({
        url:'register_verify',
        method: 'post',
        params: data
    })
}
/**
 * 发送验证码(修改密码)
 * @param {*} data 
 * @returns 
 */
export function changePwdVerify(data){
    return request({
        url:'change_pwd_verify',
        method: 'post',
        params: data
    })
}
/**
 * 数据验证
 * @param {*} data 
 * @returns 
 */
export function dataValidation(data){
    return request({
        url: 'jiyun/data_validation',
        method: 'post',
        params: data
    })
}
/**
 * 获取所有地区
 * @returns 
 */
export function getCountry(data){
    return request({
        url: 'jiyun/get_country',
        method: 'get',
        params: data
    })
}
/**
 * 预估运费
 * @param {*} data 
 * @returns 
 */
export function predictPriceList(data){
    return request({
        url: 'jiyun/predict_price_list',
        method: 'post',
        params: data
    })
}
/**
 * 获取所有城市
 * @param {*} data 
 * @returns 
 */
export function cityList(data){
    return request({
        url: 'area/city_lists',
        method: 'get',
        params: data
    })
}
/**
 * 提交注册信息
 * @param {*} data 
 * @returns 
 */
export function submitRegister(data){
    return request({
        url: 'jiyun/submit_register',
        method: 'post',
        params: data
    })
}
/**
 * 修改密码
 * @param {*} data 
 * @returns 
 */
export function resetPwd(data){
    return request({
        url: 'jiyun/user/reset_pwd',
        method: 'post',
        params: data
    })
}
/**
 * 登录
 * @param {*} data 
 * @returns 
 */
export function submitLogin(data){
    return request({
        url: 'jiyun/submit_login',
        method: 'post',
        params: data
    })
}
/**
 * 获取用户信息
 * @returns 
 */
export function getUserInfo(){
    return request({
        url: 'user',
        method: 'get'
    })
}
/**
 * 修改用户信息
 * @param {*} data 
 * @returns 
 */
export function changeInfo(data){
    return request({
        url: 'user/change_info',
        method: 'post',
        params: data
    })
}
/**
 * 修改密码
 * @param {*} data 
 * @returns 
 */
export function changePwd(data){
    return request({
        url: 'user/change_pwd',
        method: 'post',
        params: data
    })
}
/**
 * 退出登录
 * @returns 
 */
export function submitLogout(){
    return request({
        url: 'submit_logout',
        method: 'get',
    })
}
/**
 * 统计用户消息未读数
 * @returns 
 */
export function noRead(){
    return request({
        url: 'message/no_read',
        method: 'get',
    })
}
/**
 * 消息列表
 * @param {*} data 
 * @returns 
 */
export function messageList(data){
    return request({
        url: 'message/list',
        method: 'get',
        params: data
    })
}
/**
 * 全部已读
 * @param {*} data 
 * @returns 
 */
export function allRead(data){
    return request({
        url: 'message/all_read',
        method: 'get',
    })
}
/**
 * 获取代理banner
 * @returns 
 */
export function bannerList(data){
    return request({
        url: 'jiyun/agent/banner_list',
        method: 'get',
        params: data
    })
}
/**
 * 货物分类
 * @returns 
 */
export function getCategory(){
    return request({
        url: 'jiyun/get_category',
        method: 'get',
    })
}
/**
 * 包裹报备
 * @param {*} data 
 * @returns 
 */
export function additionReport(data){
    return request({
        url: 'addition_report',
        method: 'post',
        params: data
    })
}
/**
 * 报备记录
 * @param {*} data 
 * @returns 
 */
export function reportingRecord(data){
    return request({
        url: 'reporting_record',
        method: 'get',
        params: data
    })
}
/**
 * 用户订单列表
 * @param {*} data 
 * @returns 
 */
export function userOrderList(data){
    return request({
        url: 'jiyun/user_order_list',
        method: 'get',
        params: data
    })
}
/**
 * 用户订单详情
 * @param {*} data 
 * @returns 
 */
export function userOrderInfo(data){
    return request({
        url: 'jiyun/user_order_info',
        method: 'get',
        params: data
    })
}
/**
 * 物流查询
 * @param {*} data 
 * @returns 
 */
export function logisticsSearch(data){
    return request({
        url: 'jiyun/logistics_search',
        method: 'get',
        params: data
    })
}
/**
 * 物流查询
 * @param {*} data 
 * @returns 
 */
export function storehouseInfo(data){
    return request({
        url: 'jiyun/storehouse_info',
        method: 'get',
        params: data
    })
}
/**
 * 文章列表
 * @param {*} data 
 * @returns 
 */
export function articleList(data){
    return request({
        url: `jiyun/article_list`,
        method: 'get',
        params: data
    })
}
/**
 * 文章详情
 * @param {*} data 
 * @returns 
 */
export function articleDetail(data){
    return request({
        url: 'jiyun/article_detail',
        method: 'get',
        params: data
    })
}
/**
 * 获取系统配置
 * @returns 
 */
export function getConfig(){
    return request({
        url: 'jiyun/system/config',
        method: 'get',
    })
}
/**
 * 阅读消息
 * @param {*} data 
 * @returns 
 */
export function msgRead(data){
    return request({
        url: 'jiyun/msg_read',
        method: 'get',
        params: data
    })
}
/**
 * 订单留言列表
 * @param {*} data 
 * @returns 
 */
export function get_order_msg_list(data){
    return request({
        url: 'jiyun/get_order_msg_list',
        method: 'get',
        params: data
    })
}
/**
 * 添加留言
 * @param {*} data 
 * @returns 
 */
export function add_order_message(data){
    return request({
        url: 'jiyun/add_order_message',
        method: 'post',
        params: data
    })
}
/**
 * 获取服务协议
 * @returns 
 */
export function getServiceAgreement(){
    return request({
        url: 'jiyun/service_agreement',
        method: 'get'
    })
}
/**
 * 主账号注册
 * @param {*} data 
 * @returns 
 */
export function main_register(data){
    return request({
        url: 'main_register',
        method: 'post',
        params: data
    })
}



/**
 * 编辑/新增地址
 */
 export function editReceiveAddress(data){
    return request({
        url: 'jiyun/edit_receive_address',
        method: 'post',
        params: data
    })
}

/**
 * 地址列表
 */
export function getReceiveAddressList(data){
    return request({
        url: 'jiyun/get_receive_address_list',
        method: 'get',
        params: data
    })
}


/**
 *  删除地址
 */
export function delAddress(data){
    return request({
        url: 'jiyun/del_address',
        method: 'post',
        params: data
    })
}

/**
 * 申请打包
 * 报备/打包  用户报备列表
 */

export function getUserReportList(data){
	return request({
	    url: 'jiyun/get_user_report_list',
	    method: 'get',
	    params: data
	})
}

/*
 报备/打包  根据获取路线列表 
*/
export function getAreaLine(id){
	return request({
	    url: 'jiyun/get_area_line?area_id='+id,
	    method: 'get'
	})
}

/*
* 报备/打包  提交打包 
*/
export function createOrder(data){
	return request({
	    url: 'jiyun/create_order',
	    method: 'post',
	    params: data
	})
}
/**
 * 编辑用户名
 * @param {*} data 
 * @returns 
 */
export function editNickname(data){
    return request({
        url: 'editNickname',
        method: 'post',
        params: data
    })
}
/**
 * 包裹详情
 * @param {*} data 
 * @returns 
 */
export function getReportInfo(data){
    return request({
        url: 'getReportInfo',
        method: 'get',
        params: data
    })
}
/**
 * 编辑收货地址
 * @param {*} data 
 * @returns 
 */
export function editShippingAddress(data){
    return request({
        url: 'editShippingAddress',
        method: 'post',
        params: data
    })
}
/**
 * 国家option
 * @returns 
 */
export function getCountryOption(){
    return request({
        url: 'getCountryOption',
        method: 'get'
    })
}
/**
 * 省州option
 * @returns 
 */
export function getProvinceOption(data){
    return request({
        url: 'getProvinceOption',
        method: 'get',
        params: data
    })
}
/**
 * 注册验证
 * @param {*} data 
 * @returns 
 */
export function register_verify(data){
    return request({
        url: 'register_verify',
        method: 'post',
        params: data
    })
}
