export default {
	'语言切换': 'переключить язык',
	'网站首页': 'титульная страница',
	login_signup: 'Авторизоваться',
	'系统消息': 'Системные сообщения',
	// 页面名称
	page_title: {
		index: 'Главная страница',
		search: '',
		
		question: '',
		question_detail: 'Подробности статьи',
		report: '',
		about_us: '',
		estimate: '',
		order_detail: '',
		user: 'мой',
		
		addressInfo: 'Адрес доставки'
	},
	'注册': 'Регистр',
	'消息列表': 'Список сообщений',
	'请输入查询单号...': 'Пожалуйста, введите номер для отслеживания...',
	'高效准时': 'Эффективнои вовремя',
	'细心服务': 'Внимательноеобслуживание',
	'便携集运': 'Удобнаяконсолидация',
	'值得信赖': 'заслуживающийдоверия',
	// 首页下单步骤
	'下单步骤': 'Операционные процедуры',
	'登录 注册': 'войти Зарегистрироваться',
	'点击左上角的“登录”,如果您还没有账号，请在登录页面点击“注册”。': 'Нажмите «Войти» в левом верхнем углу, если у вас нет учетной записи, нажмите «Регистрация» на странице входа.',
	'点击包裹预报按钮，输入您要发货的快递单号，选择包裹中的商品类别，点击提交。': 'Нажмите кнопку прогноза пакета, введите номер экспресс-доставки, который необходимо отп равить, выберите категорию товара в пакете и нажмите «Отправить».',
	'物流查询': 'Логистика запросов',
	'点击“我的订单”，点击订单详情，复制物流单号，返回首页，将订单号粘贴到查询栏，即可查询物流进度。': 'Нажмите «Мой заказ», щелкните детали заказа и скопируйте номер логистического заказа, затем вернитесь на домашнюю страницу, вставьте номер заказа в поле запроса, и вы сможете проверить ход логистики.',
	warehouse_address: 'Адрес склада',
	warehouse_address_info: 'Складская зона Hangda A, № 3, 1-я восточная дорога, промышленный проспект, промышленная зона Синьлянь, город Лишуй, район Наньхай, город Фошань, провинция Гуандун',
	recipient_info: 'Отметьте свое имя пользователя (или идентификатор) + город в столбце получателя',
	// 首页集运服务
	'集运服务': 'Служба консолидации',
	'包裹预报': 'Прогноз пакета',
	'预报您的包裹信息': 'Отчетность по предмету',
	'我的订单': 'Мой заказ',
	'查看我的集运订单': 'Порядок консолидации',
	'帮助中心': 'центр помощи',
	'您想知道的集运常见问题都在这': 'Руководство по эксплуатации',
	'关于我们': 'О компании',
	'关于我们的介绍': 'О нашем знакомстве',
	//运费估算 
	'运费估算': 'оценка стоимости доставки',
	'开始预估': '3anpoc',
	'已为您查询到线路方案：': 'Всего для вас найдено маршрута:',
	'以下结果仅供参考，详情请咨询客服': 'Следующие результаты приведены только для справки, пожалуйста, свяжитесь со службой поддержки клиентов для получения подробной информации.',
	'卢布': 'RUB',
	'物品限制': 'лимит предметов：',
	'重量限制': 'Максимальный вес：',
	'长度限制': 'предел длины：',
	'重量': 'Вес предмета',
	'首重': 'первый вес：',
	'续重': 'Продолжение：',
	'长度': 'длина',
	wide: 'Ширина',
	height: 'высокий',
	'请选择': 'пожалуйста , выбери...',
	'请输入': 'Пожалуйста входите...',
	'收货地址': 'область приема',
	search: 'Запрос',
	'地址': 'адрес',
	'复制': 'копировать',
	'一键复制': 'копирование в один клик',
	recipient: 'получатель',
	'电子邮箱': 'Электронная почта',
	'密码': 'пароль',
	'记住密码': 'Запомнить пароль',
	'找回密码': 'Запомнить',
	'登录': 'Авторизоваться',
	'验证码': 'Код проверки',
	'获取验证码': 'Получение кода проверки',
	'提交': 'Представлено',
	date: 'Дата',
	country: 'cтрана',
	region: 'pегион',
	city: 'Города',
	'下一步': 'следующий',

	//快递查询
	'当前状态': 'Текущее состояние',
	//登录
	'欢迎登录': 'Добро пожаловать в систему',
	//注册
	'验证码已发送至您的邮箱，请注意': 'Код подтверждения отправлен на ваш почтовый ящик, пожалуйста, обратите внимание',
	'新密码': 'Новый пароль',
	'请输入新密码': 'Введите новый пароль...',
	'请确认新密码': 'Подтвердите новый пароль.',
	'再次输入您的新密码': 'Введите новый пароль снова.',
	'返回登录': 'Вернуться в Регистрация',
	'登录密码': 'Пароль входа',
	'确认密码': 'Подтвердить пароль',
	'再次输入您的密码': 'Введите свой пароль снова',
	//常见问题 / 详情
	'返回': 'вернут',
	'上一篇': 'Предыдущая статья',
	next: 'Следующий пост',
	breadcumb: 'текущая позиция：Главная страница > Общий вопрос > подробн',
	//包裹报备
	'单个报备': 'На всякий случай',
	'批量报备': 'На всякий случай',
	'报备记录': 'Регистрация для газеты',
	'包裹单号': 'Трек номер',
	'货物类别': 'Категория грузов',
	'添加包裹': 'Добавьте пакет',
	'每行输入一个单号，可进行批量查询...': 'Каждая строка вводит Один номер, который может быть задан оптом',
	'预报时间': 'Время прогноза',
	'物品类型': 'Тип предмета',
	//关于我们 
	about_us: {
		warehouse_address_title: 'Адрес склада',
		dizhi: 'адрес',
		address_info: '广东省佛山市海南区丽水市新联工业区工业大道东一路3号航达A仓区',
		contact_info: 'Контактная информация',
		contact_user: 'Контактное лицо',
	},
	//订单详情
	order_detail: {
		order_number: 'номер заказа',
		return_my_center: 'возвращаться',
		send: 'отправлять',
		receive: 'получать',
		status: 'состояние',
		logistics_number: 'номер отправления',
		route: 'маршрут',
		
		rukucang: 'Входящий склад',
		package_info: 'информация об упаковке',
		
		weight:'масса',
		long: 'длинный',
		wide: 'Ширина',
		height: 'высокий',
	},
	'打包单号': 'Пакет',
	'打包时间': 'время упаковки',
	//个人中心
	user_center: {
		revise_username: 'изменить данные',
		youji_dizhi: 'Адрес доставки за границей',
		detail_address: 'Адрес',
		my_order: 'Мой заказ',
		search_order_keyword: 'Ключевые слова порядка поиска...',
		all_order_status: 'все заказы',
		order_status_10: 'упаковка',
		order_status_20: 'упакованный',
		order_status_30: 'Отправлено',
		order_status_40: 'Внутренний сбор',
		order_status_50: 'завершенный',
		order_status_60: 'Внутренний шельф',
		order_status_85: 'Объединены',
		order_number: 'номер заказа',
		
		route: 'маршрут',
		seach_btn: 'проверить логистику',
		more_btn: 'Более',
		message:'Сообщение',
	},
	'创建时间': 'Время создания	',
	//
	'请输入邮箱': 'Пожалуйста, введите почтовый ящик',
	'请输入验证码': 'Введите код веридан',
	'请输入登录密码': 'Введите пароль, пожалуйста',
	'请输入确认密码': 'Введите код подтверждения',
	'完善您的收货地址': 'Уточните адрес доставки',
	'地址填写后不能更改，请谨慎填写': 'Адрес не может быть изменен после заполнения',
	'收件者姓名': 'Имя получателя',
	'state': 'Государство/регион',
	'确认提交': 'Подтверждаю',
	'确认收货地址': 'Подтвердите адрес доставки',
	'cancel': 'изъятие',
	'confirm': 'подтверждать',
	'注册成功': 'Регистрация прошла успешно',
	'请先选择国家或地区': 'Пожалуйста, сначала выберите страну или регион',
	'请输入账号密码': 'Введите пароль, пожалуйста',
	'用户昵称': 'Ник пользователя',
	'更改': 'изменен',
	'旧密码': 'Старый пароль',
	'确认新密码': 'Подтвердите новый код',
	'更改密码': 'Смени пароль',
	'更改用户信息': 'Изменение информации пользователя',
	'退出登录确认': 'Выход из системы подтвержден',
	'您确定退出当前账户吗？': 'Вы уверены, что хотите отказаться от текущего счета?',
	'消息内容': 'Информация',
	'时间': 'время',
	'请输入快递单号': 'Введите номер доставки',
	'报备成功': 'Доложить об успехе',
	'退出登录': 'выход',
	'请先登录': 'Заходите',
	'common': 'всего',
	'package': 'упаковка',
	'全部已读': 'читать все',
	'暂无数据': 'Нет данных',
	'请输入查询单号': 'Введите номер запроса',
	'包裹状态': 'Режим посылки',
	'all': 'весь',
	'待入库': 'В хранилище',
	'已入库': 'На складе',
	'请登录': 'Пожалуйста, войдите',
	'你没有权限' : 'У тебя нет прав',
	'我的包裹' : 'Моя посылка',
	'物品名称' : 'Название элемента',
	'包裹备注' : 'Примечание на посылку',
	'请输入包裹备注' : 'Введите, пожалуйста, записку для посылки',
	'入库图片' : 'Складские фотографии',
	'搜索包裹关键词': 'Поиск посылок Ключевые слова',
	'报备备注': 'Занесите в протокол',
	'订单留言': 'Оставьте сообщение',
	'请输入内容': 'Введите, пожалуйста',
	'send': 'Отправить',
	'聊天记录': 'Запись разговора',
	'请输入发送内容': 'Введите содержимое отправки',
	'是否超大包裹': 'Не слишком большой пакет',
	yes: 'принадлежащий',
	no: 'отказаться',
	'异常原因': 'Аномальная причина',
	'包裹类型': 'Тип упаковки',
	'请阅读并同意协议': 'Пожалуйста, читайте и соглашайтесь на соглашение',
	'我不同意': 'Я не согласен',
	'同意并继续': 'Согласиться и продолжать',
	'入库备注': 'В хранилище',
	'申报价值': 'Декларировать ценность',
	'是否打木架': 'Нужна ли деревянная рама',
	'木架费': 'Плата за деревянную полку',
	'流程不符，请先注册': 'Процесс не соответствует, пожалуйста, сначала зарегистрируйтесь',
	'到付金额': 'До суммы',
	'包裹重量': 'Вес пакета',
	'未设置': 'Не установлено',



	'消息':'Сообщение',
	'已打包':'Поступили',
	'已合包':'Упакованы',
	'已发货':'Отправлено',
	'国外已签收':'На границе',
	'更改用户名':'Изменить имя',
	'所有订单':'Все заказы',
	'申请打包':'Подать заявку на упаковку',
	'地址管理':'Управление адресами',
	'查询':'Запрос',
	'国外签收':'На границе',
	'国外上架':'в Актобе',
	'已完成':'Получено',
	'搜索订单关键词':'Ключевые слова поискового заказа',
	'全部':'Все.',
	'状态':'Статус',
	'路线':'Маршрут',
	'总重量':'Общий вес',
	'添加地址':'Добавить адрес',
	'删除':'Удалить',
	'修改地址':'Изменить адрес',
	'删除地址':'Удалить адрес',
	'删除成功':'Удалить успешно',
	'新建地址':'Новый адрес',
	'收件人':'Получатель',
	'联系电话':'Контактный телефон',
	'国家/地区':'Страна/регион',
	'城市':'Города',
	'详细地址':'Подробный адрес',
	'修改密码':'Изменить пароль',
	'复制成功':'Копировать успешно',
	'复制失败':'Ошибка копирования',
	'订单详情':'Подробности заказа',
	'订单号':'Номер заказа',
	'寄':'send',
	'收':'put',
	'查询物流':'Логистика запросов',
	'物流单号':'Логистический номер',
	'入库仓':'Склад',
	'打包图片':'Упаковка фотографий',
	'运费':'Транспортные расходы',
	'支付时间':'Время оплаты',
	'合包时间':'Время упаковки',
	'发货时间':'Время отгрузки',
	'到达国外仓时间':'Время прибытия',
	'签收时间':'Время подписания',
	'打包信息':'Упаковка информации',
	'长':'Длина',
	'宽度':'Ширина',
	'高':'Высота',
	'体重':'Вес',
	'未登录':'Не зарегистрирован',
	'取消':'Отменить',
	'确认':'Подтверждение',

	'管理我的收货地址': 'Проверь мой адрес доставки',
	'申请包裹合包寄出': 'Запрос на получение пакета',
	'已选择包裹': 'Посылка выбрана.',
	'快递单号': 'Номер посылки',
	'海外收货地址': 'Адрес доставки за границей',
	'更换':'замен',
	'请选择收货地址':'Пожалуйста, выберите адрес доставки',
	'选择': 'выбор',
	'请选择路线':'Пожалуйста, выберите маршрут.',
	'备注':'примечан',
	'请输入备注': 'Пожалуйста, введите примечание',
	'请选择包裹':'Пожалуйста, выберите пакет.',
	'操作成功':'Операция выполнена.',
	'去打包': 'Собирайся',
	'省/州': 'Провинция/область',
	'邮编': 'Почтовый Индекс',
	'长宽高': 'Длинный и высокий',
	'报备时间': 'Время для газеты',
	'入库时间': 'Время входа в хранилище',
	'添加成功': 'Добавить успех',
	'提交成功': 'Представлять успех',
	'请重新选择国家或地区': 'Пожалуйста, выберите страну или регион',
	'大包单号': 'Большой пакет',
	'点击可查看包裹轨迹': 'Нажмите, чтобы увидеть траекторию посылки',
	'长宽高': '长宽高',
	'你的ID': 'Ваш клиентский код',
	'请填写ID号段为900001-999999范围内的数字': 'Пожалуйста, заполните номер идентификатора в диапазоне 900001 - 99999',
	'请输入你的ID': 'Введите свой ID',
	'当前': 'текущий',
	ru_lang: 'Русский',
	zh_lang: '简体中文',
	en_lang:'English',
}